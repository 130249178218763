.fade {
    transition: opacity 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}


.align-item-center {
    display: grid;
    align-items: center;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.backdrop-filter-blur {
    backdrop-filter: blur(4px);
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-flex {
    display: flex!important;
}

.justify-content-center {
    justify-content: center;
}

.w-100 {
    width: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.p-10 {
    padding: 10px;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.fw-800 {
    font-weight: 800!important;
}

.fw-700 {
    font-weight: 700!important;
}

.fw-600 {
    font-weight: 600!important;
}

.fw-300 {
    font-weight: 300!important;
}

.fw-200 {
    font-weight: 200!important;
}

.w-10 {
    width: 10%!important;
}
.w-30 {
    width: 30%!important;
}
.w-20 {
    width: 20%!important;
}
.w-40 {
    width: 40%!important;
}
.w-50 {
    width: 50%!important;
}
.w-60 {
    width: 60%!important;
}
.w-70 {
    width: 70%!important;
}
.w-80 {
    width: 80%!important;
}
.w-90 {
    width: 90%!important;
}

@media (max-width: 991px) {
    .w-10,
    .w-20,
    .w-30,
    .w-40,
    .w-50,
    .w-60,
    .w-70,
    .w-80,
    .w-90 {
        width: 100%!important;
    }
}

.fs-16 {
    font-size: 16px;
}

.br-8 {
    border-radius: 8px;
}