/** Buttons **/

 .ae_link-btn {
    min-width: 190px;
    padding: 18px 45px;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    border-radius: 32px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    transition: all .3s ease-out;
    position: relative;
    border: none;
  }
  
  .btn-gradient {
    color: #fff;
    overflow: hidden;
    /* z-index: 1; */
    background: var( --gradientPrimary);
  }
  
  .btn-pink {
    background: var(--gradientPink);  
  }
  
  .btn-blue {
    background: var(--gradientBlue);
  }
  
  .btn-gradient::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: all .3s ease-out;
    background: var( --gradientPrimary);
  }
  
  .btn-gradient:hover {
    color: #fff;
  }
  
  .btn-gradient:hover::before {
    opacity: 1;
  }
  
  .btn-gradient:not(.btn-pink,.btn-blue):hover {
    opacity: 0.65;
  }
  
  .btn-pink::before {
    background: var(--primaryPink);
  }
  
  .btn-blue::before {
    background: var(--primaryBlue);
  }
  
  .btn-outline-blue {
    color: var(--blueDark);
    border: 1px solid var(--blueDark);
  }
  
  .btn-outline-blue:hover {
    color: #fff;
    background: var(--blueDark);
  }
  
  .btn-outline-pink {
    color: var(--pinkDark);
    border: 1px solid var(--pinkDark);
  }
  
  .btn-outline-pink:hover {
    color: #fff;
    background: var(--pinkDark);
  }
  