/**
 *
 * Form Elements
 *
 */

input[type="email"],
input[type="text"],
input[type="password"],
input[type="number"],
select,
textarea,
.ae_custom-dropdown {
    padding: 16px;
    background: transparent;
    font-weight: 300;
    font-size: 16.5px;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid var(--gray4);
    width: 100%;
}

/*input:valid,*/
input[type="email"].success,
input[type="text"].success,
input[type="password"].success,
input[type="number"].success,
select.success,
textarea.success {
    border-color: var(--green);
}

/*input:invalid,*/
input[type="email"].danger,
input[type="text"].danger,
input[type="password"].danger,
input[type="number"].danger,
select.danger,
textarea.danger {
    border-color: var(--red);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

select {
    appearance: none;
    background: url("/images/chevron-down.svg") no-repeat 95% 25px!important;
    padding-right: 40px!important;
}


label {
    font-weight: 300;
    font-size: 16.5px;
    line-height: 24px;
    width: 100%;
}

input[type="checkbox"],
input[type="radio"],
input[type="file"] {
    /*visibility: hidden;*/
    opacity: 0;
    position: absolute;
}

input[type="checkbox"]:focus-visible + label,
input[type="radio"]:focus-visible + label,
input[type="file"]:focus-visible + label,
input[type="file"]:focus-visible ~ span,
.ae_custom-dropdown input[type="radio"]:focus-visible + span {
    outline: #000 auto 1px;
}

.ae_checkbox,
.ae_radio {
    position: relative;
}

.ae_checkbox > label,
.ae_radio > label {
    display: block;
    padding-left: 40px;
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
    font-weight: normal;
    cursor: pointer;
}


.ae_checkbox > label::after,
.ae_radio > label::after  {
    content: "";
    border-radius: 4px;
    border: 1px solid var(--gray4);
    width: 28px;
    height: 28px;
    position: absolute;
    left: 8px;
    top: -2px;
}

.ae_checkbox > label::before,
.ae_radio > label::before {
    content: "";
    border-radius: 2px;
    width: 16px;
    height: 16px;
    background: var(--pinkDark);
    position: absolute;
    left: 14px;
    top: 4px;
    opacity: 0;
    transition: all .3s ease-out;
}

.ae_radio > label::before,
.ae_radio > label::after {
    border-radius: 50px;
}

.ae_checkbox > input:checked + label::before,
.ae_radio > input:checked + label::before {
    opacity: 1;
}

.ae_checkbox.right > label,
.ae_radio.right > label {
    padding-right: 40px;
    text-align: right;
}

.ae_checkbox.right > label::after,
.ae_radio.right > label::after  {
    left: auto;
    right: 8px;
}

.ae_checkbox.right > label::before,
.ae_radio.right > label::before {
    left: auto;
    right: 14px;
}

.ae_form-field {
    width: 100%;
    padding: 0 8px;
    margin: 0 0 16px;
    position: relative;
}

.ae_custom-dropdown {
    padding: 0;
    /*overflow: hidden;*/
    position: relative;    
    max-width: 595px;
}

.ae_custom-dropdown::before {
    content: url("/images/chevron-down.svg");
    position: absolute;
    right: 24px;
    top: 16px;
    transition: all .3s ease-out;
    pointer-events: none;
}

.ae_custom-dropdown.open::before {
    transform: rotate(180deg);
}

.ae_custom-dropdown > span {
    padding: 16px 50px 16px 16px;
    display: block;
    cursor: pointer;
}

.ae_custom-dropdown .wrap {
    max-height: 0;
    transition: all .3s ease-out;
    position: absolute;
    z-index: -1;
    opacity: 0;
    background: #fff;
    border: 1px solid var(--gray4);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    top: 45px;
    left: -1px;
    width: calc(100% + 2px);
}

.ae_custom-dropdown.open .wrap,
.ae_custom-dropdown:focus-visible .wrap {
    height: auto;
    max-height: 500px;
    z-index: 1;
    opacity: 1;
}

.ae_custom-dropdown .wrap label > span {
    border-bottom: 1px solid var(--gray5);
    padding: 13px 16px;
    display: block;
}

.ae_custom-dropdown .wrap label:hover > span,
.ae_custom-dropdown .wrap label > input:checked + span {
    background: var(--gray6);
}

.ae_custom-dropdown .wrap label:last-of-type {
    border: none;
}

.ae_upload-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.ae_upload-fields p {
    width: 100%;
}

.ae_file {
    width: 212px;
    display: grid;
    align-items: center;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.ae_file:not(.common)::before {
    content: "";
    width: 100%;
    height: 150px;
    background: url("/images/img-upload-decor.svg") no-repeat center rgba(239, 233, 246, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
}

.ae_file > img[src="#"] {
    opacity: 0;
}

.ae_file > img {
    background: rgba(239, 233, 246, 0.2);
    border-radius: 8px;
    width: 100%;
    height: 150px;
    margin: 0 auto;
    object-fit: contain;
    opacity: 1;
    transition: all .3s ease-out;
}

.ae_file > span {
    display: block;
    margin-top: 20px;
}

.ae_file.common {
    width: 100%;
    text-align: left;
}

label.ae_file.common > span:first-of-type {
    border-radius: 32px;
    padding: 18px;
    width: 260px;
    border: 1px solid var(--gray3);
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    transition: all .3s ease-in;
}

label.ae_file.common > span:first-of-type:hover {
    background-color: var(--bluePale);
}

.ae_form-helper {
    display: inline-block;
    position: absolute;
    top: -6px;
    right: 0px;
}

.ae_form-helper > span {
    color: #000;
    font-weight: 700;
    color: #4f4f4f;
    font-weight: 700;
    background: #fff;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    box-shadow: 0 0 11px -2px rgb(0 0 0 / 18%);
    cursor: pointer;
}

.ae_form-helper > div {
    position: absolute;
    background-color: #fff;
    padding: 10px;
    width: 300px;
    border-radius: 8px;
    box-shadow: 0 0 11px -2px rgb(0 0 0 / 18%);
    z-index: -1;
    top: 0;
    right: 24px;
    opacity: 0;
    transition: all .3s ease-in;
}

.ae_form-helper > span:hover + div,
.ae_form-helper > span:focus + div {
    opacity: 1;
    z-index: 2;
}

.form-reset {
    background: #fff;
    color: var(--pinkDark);
    border-radius: 4px;
    padding: 3px 10px;
    text-decoration: none;
    border: none;
}

.form-reset:hover {
    color: #fff;
}

form a {
    color: var(--pinkDark);
    text-decoration: none;
    font-weight: 700;
    border-bottom: 1px solid var(--pinkDark);
    transition: all .3s ease-out;
}

form a:hover {
    opacity: 0.7;
    color: var(--pinkDark);
}

form p {
    font-weight: 300;
    font-size: 16.5px;
    line-height: 24px;
    margin-bottom: 12px!important;
}


/**
 *
 * Specific Forms 
 *
 */

.ae_availability-form {
    display: flex;
    flex-wrap: wrap;
    backdrop-filter: blur(4px);
    border-radius: 8px;
    padding: 11% 16%;
    box-shadow: 0px 0px 32px rgba(17, 18, 21, 0.07);
}

.ae_form-field > h3.package-title {
    margin-bottom: 20px;
}

.ae_availability-form .provjeri_btn {
    margin-top: 16px;
}


@media (max-width: 768px) {
    .ae_availability-form {
        padding: 16px;
        margin-top: 40px;
    }
}

.ae_order-form .step-1 .right label {
    padding-left: 0;
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: var(--pinkDark);
    text-align: left;
}

.ae_order-form .step-1 .ae_form-field {
    padding: 30px 0;
    border-bottom: 1px solid var(--gray5);
}

.ae_order-form .step-1 .ae_radio > label::after {
    top: 35px;
}

.ae_order-form .step-1 .ae_radio > label::before {
    top: 41px;
}

.ae_order-form .step-2 {
    margin-top: 20px;
}

.ae_order-form .step-2,
.ae_order-form .step-3,
.ae_order-form .step-total {
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease-out;
    opacity: 0;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
}

.ae_order-form .step-1,
.ae_order-form .step-2.open,
.ae_order-form .step-3.open,
.ae_order-form .step-total.open {
    max-height: 3000px;
    opacity: 1;
    position: static;
}

.ae_order-form .step-2 > *,
.ae_order-form .step-3 > *,
.ae_order-form .step-total > * {
    width: 100%;
}

.ae_order-form .step-3.open {
    padding: 80px 0 40px;
    position: static;
}

.ae_order-form .step-total.open {
    border-top: 1px solid var(--gray5);
    padding-top: 40px;
    position: static;
}

.ae_order-form .step-1 {
    transition: .3s ease-out;
    overflow: hidden;
    padding: 0 8px;
}

.ae_order-form .step-1.close {
    max-height: 0;
    opacity: 0;
}

.ae_order-form p.title {
    font-size: 28px;
    line-height: 40px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0 8px;
    margin-top: 30px;
}

.ae_order-form hr + p.title {
    margin-top: 0;
}

.ae_order-form p.total-text {
    font-weight: 300;
    font-size: 28px;
    line-height: 40px;
}

.ae_order-form #cartTotal {
    display: none;
}

form a.step-3-open {
    display: none;
}

form a.step-3-open.hide {
    display: none!important;
}

.ae_contact-later-form {
    max-width: 444px;
    display: flex;
    flex-wrap: wrap;
}

/** Poslovni Korisnici -- color overrides **/

body.privatni .ae_availability-form {
    background: rgba(239, 233, 246, 0.3);
}

body.poslovni .ae_availability-form {
    background: rgba(233, 243, 246, 0.5);
}

body.poslovni .ae_checkbox > label::before,
body.poslovni .ae_radio > label::before {
    background: var(--blueDark);
}

body.poslovni .ae_order-form .step-1 .right label,
body.poslovni .ae_order-form .step-1 .right label,
body.poslovni form a:hover,
body.poslovni form a {
    color: var(--blueDark);
}

body.poslovni form a {
    border-color: var(--blueDark);
}

body.poslovni form .dark-pink {
    color: var(--blueDark)!important;
}

body.poslovni form .btn-pink {
    background: var(--gradientBlue);
}
body.poslovni form .btn-pink::before {
    background: var(--primaryBlue);
}

body.poslovni form .btn-pink {
    color: #fff!important;
}


@media(max-width: 500px){
    .ae_order-form .step-1 .right label {
        font-size: 23px;
    }

    .fiksnitel,
    .airtel,
    .opcijatv,
    .filmskipaket
     {
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    .ae_checkbox {
        width: fit-content !important;
    }
    .ae_checkbox label {
        padding: 0px 40px 0px 0px;
    }

    .gdprMain label {
        padding-left: 40px;
    }

    .ae_order-form p.total-text {
        font-size: 23px;
    }
  }