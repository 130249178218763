.ae_modal {
    display: none;
}
  
.ae_modal.is-open {
    display: block;
}

.ae_modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*background: rgba(0,0,0,0.6);*/
    background: rgba(233, 243, 246, 0.5);
    /*display: flex;
    justify-content: center;
    align-items: center;*/
}

.ae_modal__container {
    padding: 30px;
    max-width: 1134px;
    max-height: 80vh;
    margin: 20vh auto 0;
    /*overflow-y: auto;*/
    box-sizing: border-box;
    box-shadow: 0px 0px 32px rgba(17, 18, 21, 0.07);
    background: linear-gradient(0deg, rgba(233, 243, 246, 0.3), rgba(233, 243, 246, 0.3)), #FFFFFF;
    border-radius: 16px;
}

.ae_modal__header {
    /*display: flex;
    justify-content: space-between;
    align-items: center;*/
    text-align: center;
}

.modal__close {
    background: transparent;
    border: 0;
    position: fixed;
    right: 10px;
    top: 15px;
}
  
.ae_modal__header .modal__close:before { content: "\2715"; }
  
.ae_modal__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    color: rgba(0,0,0,.8);
}


  
  
  /**************************\
    Demo Animation Style
  \**************************/
  @keyframes mmfadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }
  
  @keyframes mmfadeOut {
      from { opacity: 1; }
        to { opacity: 0; }
  }
  
  @keyframes mmslideIn {
    from { transform: translateY(15%); }
      to { transform: translateY(0); }
  }
  
  @keyframes mmslideOut {
      from { transform: translateY(0); }
      to { transform: translateY(-10%); }
  }
  
  .micromodal-slide {
    display: none;
  }
  
  .micromodal-slide.is-open {
    display: block;
  }
  
  .micromodal-slide[aria-hidden="false"] .ae_modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="false"] .ae_modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .ae_modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .ae_modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }
  
  .micromodal-slide .ae_modal__container,
  .micromodal-slide .ae_modal__overlay {
    will-change: transform;
  }



/** Custom Modal CSS **/

.ae_modal__content {
  overflow-y: auto;
  /*height: calc(100vh - 371px);*/
  height: 100vh;
  margin-top: 0;
  padding: 0 10%;
}

.ae_modal__container img {
  display: block;
  margin: 40px auto;
  max-width: 100%;
}

.ae_modal__container .row img {
  margin: 40px 10px;
}

.ae_modal__container .ae_modal__header img {
  margin-top: -185px;
  margin-bottom: 0;
}

.ae_modal__header h2 {
  visibility: hidden;
  position: absolute;
  z-index: 0;
  top: 0;
}

.ae_modal__container p {
  max-width: 445px;
  margin: 40px auto;
}

.ae_modal__container p.fw-700 {
  margin-bottom: 0;
}

.ae_modal__container table {
  width: 100%;
  max-width: 675px;
  margin: 30px auto 60px;
}

.ae_modal__container table tr > td {
  border-bottom: .5px solid var(--gray5);
  padding: 20px 20px 20px 0;
}

.ae_modal__container table tr > td:last-of-type {
  text-align: right;
  font-weight: 700;
  padding-right: 0;
}

.modal__footer img {
  border-radius: 8px;
}

.modal__footer button {
  border: none;
  background: none;
  font-weight: bold;
  font-size: 16.5px;
  line-height: 24px;
  color: #000;
  text-transform: uppercase;
}

.ae_modal__heade-xs,
.ae_modal__heade-xs h2 {
  display: none;
}

.ae_modal__heade-xs .modal__close:before {
  content: "\2715";
}

.tv-popis .ae_modal__container {
  max-width: 600px;
  max-height: 100%;
  height: 600px;
  margin: 10% auto;
  background: #fff;
}

.tv-popis .ae_modal__content {
  height: 100%!important;
}

.tv-popis .ae_modal__container table tr > td {
  padding: 0;
  height: 100px;
}

.tv-popis .ae_modal__container table tr > td > span {
  background: linear-gradient(128.76deg, #53C8F1 11.44%, #9FC4FB 38.81%, #D1ADF5 65.84%, #EF88DA 92.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}

.tv-popis .ae_modal__container table tr > td > img {
  width: 100px;
  margin: 0 15px 0 0;
}

@media (max-width: 1140px) {
  .ae_modal__container {
    margin: 20vh 32px 0;
  }
}

@media (max-height: 1080px), (max-width: 991px) {
  .ae_modal__container {
    max-height: 100vh;
    margin: 0 auto;
    /*margin: 32px;*/
  }
  .ae_modal__content {
    height: calc(100vh - 60px)!important;
  }
  .ae_modal__heade-xs {
    display: block;
  }
  .ae_modal__header {
    display: none;
  }
}

@media (max-width: 768px) {
  .ae_modal__content {
    padding: 0;
  }
  .ae_modal__container {
    margin: 0;
  }
  .ae_modal:not(.tv-popis) .ae_modal__container table td {
    display: block;
    text-align: left!important;
  }
  .ae_modal:not(.tv-popis) .ae_modal__container table td:first-of-type {
    border: none;
    padding-bottom: 0;
  }
  .ae_modal:not(.tv-popis) .ae_modal__container table td:last-of-type {
    padding-top: 5px;
  }
  .tv-popis .ae_modal__container table td:last-of-type,
  .tv-popis .ae_modal__container table tr:first-of-type  {
    display: none;
  }
  .tv-popis .ae_modal__container table tr > td > img {
    margin: 0 auto;
  }
}


.modal-success {
  background: #7a7a7a33;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
}

.modal-success > div {
  background: #fff;
  padding: 60px 100px;
  border-radius: 8px;
  text-align: center;
  color: #4F4F4F;
}

.modal-success > div img {
  margin-bottom: 50px;
}

.modal-success > div p {
  margin-bottom: 40px;
}

.modal-success > div a,
.modal-success > div a:hover,
.modal-success > div a:before
{
  padding: 15px 30px;
  border-radius: 30px;
  color: #fff !important;
  border: none;
  cursor: pointer;
}

@media(max-width: 500px){
  .modal-success > div {
    padding: 60px 40px;
  }
}