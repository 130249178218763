@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&display=swap');

@import url(reboot.css);
@import url(grid.css);
@import url(helpers.css);
@import url(colors.css);
@import url(buttons.css);
@import url(modal.css);
@import url(forms.css);
@import url(components.css);


:root {
  --primaryBlue: #53C8F1;
  --primaryPink: #EF88DA;
  --blueDark: #3A8CA9;
  --blueLight: #87D9F5;
  --bluePale: rgba(233, 243, 246, 0.5);
  --pinkDark: #BC47A4;
  --pinkLight: #DAAFD1;
  --pinkPale: rgba(239, 233, 246, 0.5);
  --black: #111215;
  --gray1: #333333;
  --gray2: #4F4F4F;
  --gray3: #828282;
  --gray4: #BDBDBD;
  --gray5: #E0E0E0;
  --gray6: #F2F2F2;
  --gray7: #F8F8F8;
  --gradientBlue: linear-gradient(144.08deg, #53C8F1 0%, #42A0C1 100%);
  --gradientPink: linear-gradient(144.08deg, #EF88DA 0%, #BC47A4 100%);
  --gradientPrimary: linear-gradient(128.76deg, #53C8F1 11.44%, #9FC4FB 38.81%, #D1ADF5 65.84%, #EF88DA 92.28%);
  --red: #DA1212;
  --green: #27AE60;
  --disabled: #717171;
}

.show-poslovni, .show-privatni {
  display: none;
}

body.privatni .show-privatni {
  display: block;
}

body.poslovni .show-poslovni {
  display: block;
}

.footer-1 p.show-poslovni, .footer-1 p.show-privatni {
  margin-bottom: 0!important;
}

body[class*="common_"] .show-privatni,
body[class*="common_"] .show-poslovni {
  display: block;
}

/** HOME **/

body.home.privatni {
  background-image: url("/images/home-bg-privatni.jpg"), url("/images/footer-bg-min.jpg");
}

body.home.poslovni,
body[class*="common_"] {
  background-image: url("/images/home-bg-poslovni.jpg"), url("/images/footer-bg-min.jpg");
}

@media (max-width: 1920px) {
  body::before {
    background-size: 1920px;
  }
}



/** HOME - SECTIONS **/

.ae_home-section-services {
  padding-top: 180px;
}

.ae_section-form-availability .decor-img {
  box-shadow: 0px 0px 32px rgba(17, 18, 21, 0.07);
  border-radius: 8px;
  margin: 70px 0;
  max-width: 100%;
}

.ae_section-form-availability .col-6:first-of-type {
  padding-right: 10%;
}

.ae_section-form-package,
.ae_section-form-error {
  padding: 84px 0;
}

.ae_section-form-package .wrapper,
.ae_section-form-error .wrapper {
  background: #FFFFFF;
  box-shadow: 0px 0px 32px rgba(17, 18, 21, 0.07);
  border-radius: 8px;
  padding: 5.8% 12%;
}

.ae_section-form-package .wrapper > p,
.ae_section-form-error .wrapper p {
  padding: 0 8px;
}

@media (max-width: 991px) {
  .ae_home-section-services {
    padding-top: 50px;
  }
  .ae_section-form-availability .col-6 {
    width: 100%;
    padding-right: 0!important;
  }
}

@media (max-width: 768px) {
  .ae_section-form-package .container,
  .ae_section-form-error .container {
    padding: 0;
  }
  .ae_section-form-package .wrapper,
  .ae_section-form-error .wrapper {
    padding: 32px;
  }
  .ae_home-section-packages .container-fluid {
    padding: 0;  
  }
  .ae_section-form-error .row > div[class*="col-"] {
    width: 100%;
  }
  .ae_section-form-error .text-center {
    text-align: left!important;
    margin-bottom: 20px;
  }
}

/** PAGE - OPTIKA RUNE **/

body.optika_rune_privatni.privatni {
  background-image: url("/images/header-rune-privatni-min.jpg"), url("/images/footer-bg-min.jpg");
}

body.optika_rune_poslovni.poslovni {
  background-image: url("/images/header-rune-poslovni-min.jpg"), url("/images/footer-bg-min.jpg");
}

.ae_section-options-1 > .container > h3 {
  margin: 105px 0 48px;
}

.ae_decor-img-section .decor-img {
  max-width: 100%;
  padding: 0 32px;
  margin: 92px 0;
}

.ae_section-options-2 {
  padding: 117px 0 177px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 194px;
}

body.privatni .ae_section-options-2 {
  background-image: url("/images/options-bg-privatni-min.jpg");
}

body.poslovni .ae_section-options-2 {
  background-image: url("/images/options-bg-poslovni-min.jpg");
}

.ae_section-options-2 > .container:first-of-type {
  max-width: 588px;
  padding-bottom: 48px;
}

.ae_section-options-2 .ae_package-option h3::before,
.ae_section-options-2 .ae_package-option h4::before {
  background: var(--gray6);
}

.ae_section-options-2 .ae_package-option:last-of-type svg {
  height: 100px;
}

.ae_section-options-2 .ae_package-option {
  border-color: var(--gray6);
}

@media (max-width: 1199px) {
  .ae_section-options-2 {
    margin-bottom: 100px;
    padding: 100px 0;
  }
}

@media (max-width: 768px) {
  .ae_section-options-2 {
    margin-bottom: 50px;
    padding: 50px 0;
  }
}

/** Page Airfiber **/

body.optika_airfiber_poslovni.poslovni {
  background-image: url("/images/header-airfiber-poslovni-min.jpg"), url("/images/footer-bg-min.jpg");
}

body.optika_airfiber_privatni.privatni {
  background-image: url("/images/header-airfiber-privatni-min.jpg"), url("/images/footer-bg-min.jpg");
}


/** Page Parica **/

body.parica_poslovni.poslovni {
  background-image: url("/images/header-parica-poslovni-min.jpg"), url("/images/footer-parica-poslovni-min.jpg");
}

body.parica_privatni.privatni {
  background-image: url("/images/header-parica-privatni-min.jpg"), url("/images/footer-parica-privatni-min.jpg");
}

body[class*="parica_"] .ae_section-options-1 .container {
  max-width: 1030px;
}

body[class*="parica_"] .ae_section-options-1 .ae_package-option {
  max-width: 500px;
  margin: 0 auto;
}


body[class*="parica_"] .ae_decor-img-section .decor-img {
  margin: 122px 0 205px;
}

/** Page Airnet Wireless **/
body[class*="airnet_wireless_"] .ae_section-options-1 {
  padding-bottom: 200px;
}

body.airnet_wireless_privatni.privatni {
  background-image: url("/images/header-anwireless-privatni-min.jpg"), url("/images/footer-anwireless-privatni-min.jpg");
}

body.airnet_wireless_poslovni.poslovni {
  background-image: url("/images/header-anwireless-privatni-min.jpg"), url("/images/footer-anwireless-privatni-min.jpg");
}


@media (max-width: 1199px) {
  body[class*="airnet_wireless_"] .ae_section-options-1 {
    padding-bottom: 100px;
  }
}

@media (max-width: 768px) {
  body[class*="airnet_wireless_"] .ae_section-options-1 {
    padding-bottom: 50px;
  }
}



.hide {
  display: none !important;
}