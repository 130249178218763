.container {
    width: 100%;
    margin: 0 auto;
    max-width: 1364px;
    padding-left: 32px;
    padding-right: 32px;
}

.container-fluid {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding-left: 32px;
    padding-right: 32px;
}

@media(max-width: 1364px) {
    /*
    .container-fluid.p-0,
    .container.p-0 {
        padding-left: 32px!important;
        padding-right: 32px!important;
    }
    */
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col {
    flex: 1 0 0%;
}

.row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%;
}
  
.row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%;
}
  
.row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
}
  
.row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%;
}
  
.row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%;
}
  
.row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
}

@media(max-width: 1199px) {
  .row-cols-5 > *,
  .row-cols-6 > * {
    width: 33.3333333333%;
  }
  .row-cols-4 > * {
    width: 50%;
  }
}

@media(max-width: 991px) {
  .row-cols-5 > *,
  .row-cols-6 > * {
    width: 50%;
    margin-bottom: 16px;
  }
}

@media(max-width: 768px) {
  .row-cols-2 > *,
  .row-cols-3 > *,
  .row-cols-4 > *,
  .row-cols-5 > *,
  .row-cols-6 > * {
    width: 100%;
  }
}

  .col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  
  .col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  
  .col-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  
  .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  
  .col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  
  .col-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  
  .col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  
  .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  
  .col-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  
  .col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  
  .col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  
  .col-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  
  .offset-1 {
    margin-left: 8.33333333%;
  }
  
  .offset-2 {
    margin-left: 16.66666667%;
  }
  
  .offset-3 {
    margin-left: 25%;
  }
  
  .offset-4 {
    margin-left: 33.33333333%;
  }
  
  .offset-5 {
    margin-left: 41.66666667%;
  }
  
  .offset-6 {
    margin-left: 50%;
  }
  
  .offset-7 {
    margin-left: 58.33333333%;
  }
  
  .offset-8 {
    margin-left: 66.66666667%;
  }
  
  .offset-9 {
    margin-left: 75%;
  }
  
  .offset-10 {
    margin-left: 83.33333333%;
  }
  
  .offset-11 {
    margin-left: 91.66666667%;
  }

  .justify-content-start {
    justify-content: flex-start !important;
  }
  
  .justify-content-end {
    justify-content: flex-end !important;
  }
  
  .justify-content-center {
    justify-content: center !important;
  }
  
  .justify-content-between {
    justify-content: space-between !important;
  }
  
  .justify-content-around {
    justify-content: space-around !important;
  }
  
  .justify-content-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-center {
    align-items: center;
  }