:root {
    /** COLORS **/
    --primaryBlue: #53C8F1;
    --primaryPink: #EF88DA;
    --blueDark: #3A8CA9;
    --blueLight: #87D9F5;
    --bluePale: rgba(233, 243, 246, 0.5);
    --pinkDark: #BC47A4;
    --pinkLight: #DAAFD1;
    --pinkPale: rgba(239, 233, 246, 0.5);
    --black: #111215;
    --gray1: #333333;
    --gray2: #4F4F4F;
    --gray3: #828282;
    --gray4: #BDBDBD;
    --gray5: #E0E0E0;
    --gray6: #F2F2F2;
    --gray7: #F8F8F8;
    --gradientBlue: linear-gradient(144.08deg, #53C8F1 0%, #42A0C1 100%);
    --gradientPink: linear-gradient(144.08deg, #EF88DA 0%, #BC47A4 100%);
    --gradientPrimary: linear-gradient(128.76deg, #53C8F1 11.44%, #9FC4FB 38.81%, #D1ADF5 65.84%, #EF88DA 92.28%);
    --red: #DA1212;
    --green: #27AE60;
    --disabled: #717171;
}

/** Colors **/

.primary-blue {
    color: var(--primaryBlue)!important;
}

.primary-pink {
    color: var(--primaryPink)!important;
}

.dark-blue {
    color: var(--blueDark)!important;
}

.light-blue {
    color: var(--blueLight)!important;
}

.pale-blue {
    color: var(--bluePale)!important;
}

.dark-pink {
    color: var(--pinkDark)!important;
}

.light-pink {
    color: var(--pinkLight)!important;
}

.pale-pink {
    color: var(--pinkPale)!important;
}

.text-white {
    color: #fff;
}

.color-danger {
    color: var(--red)!important;
}

.color-success {
    color: var(--green)!important;
}

.bg-danger {
    background: #f3e0e0!important;
}

.bg-success {
    background: #ddf5e7!important;
}

.border-danger {
    border: 2px solid var(--red)!important;
    border-radius: 4px!important;
}

.border-success {
    border: 2px solid var(--green)!important;
    border-radius: 4px!important;
}

.error-status {
    border: 2px solid var(--red)!important;
    border-radius: 4px!important;
    color: var(--red)!important;
    background: #f3e0e0!important;
    padding: 10px 20px!important;
}

.success-status {
    border: 2px solid var(--green)!important;
    border-radius: 4px!important;
    color: var(--green)!important;
    background: #ddf5e7!important;
    padding: 10px 20px!important;
}

/** Backgrounds **/

.primary-blue-bg {
    background-color: var(--primaryBlue)!important;
}

.primary-pink-bg {
    background-color: var(--primaryPink)!important;
}

.dark-blue-bg {
    background-color: var(--blueDark)!important;
}

.light-blue-bg {
    background-color: var(--blueLight)!important;
}

.pale-blue-bg {
    background-color: var(--bluePale)!important;
}

.dark-pink-bg {
    background-color: var(--pinkDark)!important;
}

.light-pink-bg {
    background-color: var(--pinkLight)!important;
}

.pale-pink-bg {
    background-color: var(--pinkPale)!important;
}