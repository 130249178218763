/** Main **/

body,
html {
    font-family: "Nunito", sans-serif;
    font-weight: 300;
    color: var(--gray2);
}

body {
    position: relative;
    background-color: #fcfcfd;
    background-size: 100%, 100%;
    background-position: center top, center bottom;
    background-repeat: no-repeat, no-repeat;
}

@media (max-width: 1920px) {
    body {
        background-size: 1920px;
    }
}

strong,
b {
    font-weight: 700;
}

hr {
    background: var(--gray5);
    margin: 40px 0;
    opacity: 1;
}

h1,
h2,
h3 {
    font-weight: 600;
    margin-bottom: 64px;
}

h4,
h5,
h6 {
    font-weight: 600;
    margin-bottom: 32px;
}

a {
    transition: all 0.3s ease-in;
    text-decoration: none;
    transition: all 0.3s ease-in;
    position: relative;
}

/*body.privatni a:not(.ae_link-btn) {    
    color: var(--pinkDark);
}*/

body.home a.link-privatni:hover,
body.privatni a:not(.ae_link-btn) {
    color: var(--primaryPink);
    font-weight: 700;
    font-size: 16px;
}

/*body.poslovni a:not(.ae_link-btn) {
    color: var(--blueDark);
}*/

body.home a.link-poslovni:hover,
body.poslovni a:not(.ae_link-btn) {
    color: var(--primaryBlue);
    font-weight: 700;
    font-size: 16px;
}

/** Responsive typography **/

@media screen and (min-width: 320px) {
    h1 {
        font-size: calc(41px + 31 * ((100vw - 320px) / 1920));
        line-height: calc(48px + 44 * ((100vw - 320px) / 1920));
    }
    h2 {
        font-size: calc(34px + 26 * ((100vw - 320px) / 1920));
        line-height: calc(40px + 32 * ((100vw - 320px) / 1920));
    }
    h3 {
        font-size: calc(28px + 13 * ((100vw - 320px) / 1920));
        line-height: calc(32px + 16 * ((100vw - 320px) / 1920));
    }
    h4 {
        font-size: calc(24px + 11 * ((100vw - 320px) / 1920));
        line-height: calc(32px + 16 * ((100vw - 320px) / 1920));
    }
    h5 {
        font-size: calc(20px + 8 * ((100vw - 320px) / 1920));
        line-height: calc(24px + 8 * ((100vw - 320px) / 1920));
    }
    h6 {
        font-size: calc(17px + 3 * ((100vw - 320px) / 1920));
        line-height: calc(24px + 8 * ((100vw - 320px) / 1920));
    }
}

@media screen and (min-width: 1920px) {
    h1 {
        font-size: 72px;
        line-height: 88px;
    }
    h2 {
        font-size: 60px;
        line-height: 72px;
    }
    h3 {
        font-size: 41px;
        line-height: 48px;
    }
    h4 {
        font-size: 35px;
        line-height: 48px;
    }
    h5 {
        font-size: 28px;
        line-height: 32px;
    }
    h6 {
        font-size: 20px;
        line-height: 32px;
    }
}

p {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 20px;
}

p:last-of-type {
    margin-bottom: 0;
}

/** Scrollbar **/

.ae_scrollbar::-webkit-scrollbar {
    width: 11px;
}
.ae_scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--gray4);
}
.ae_scrollbar::-webkit-scrollbar-track {
    background: var(--gray7);
}
.ae_scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--gray4);
    border-radius: 6px;
    border: 3px solid transparent;
}

/** Nav Pills + Tabs **/

.ae_navpills {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.ae_navpills li {
    list-style: none;
}

.ae_navpills a {
    padding: 16px;
    font-size: 18px;
    line-height: 32px;
    font-weight: 700;
    min-width: 338px;
    background: transparent;
    border: 2px solid var(--gray5);
    border-radius: 8px;
    text-align: center;
    display: block;
}

body.privatni .ae_navpills a,
body.poslovni .ae_navpills a {
    color: var(--gray3);
}

.ae_navpills li:first-of-type a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.ae_navpills li:last-of-type a {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
}

.ae_navpills li a:not(.active):hover {
    background: var(--gray6);
}

.ae_navpills li:first-of-type a.active {
    border-color: var(--pinkDark);
    background-color: var(--pinkDark);
    color: #fff;
}

.ae_navpills li:last-of-type a.active {
    border-color: var(--blueDark);
    background-color: var(--blueDark);
    color: #fff;
}

.ae_tab-content .tab-pane {
    display: none;
}

.ae_tab-content .tab-pane.active {
    display: block;
}

@media (max-width: 768px) {
    .ae_navpills a {
        min-width: auto;
    }
}

/** Packages **/

.ae_package {
    /*padding: 72px;*/
    padding: 15%;
    height: 100%;
    min-height: 606px;
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    backdrop-filter: blur(4px);
    border-radius: 8px;
}

.ae_package.rune-badge::before {
    content: "";
    position: absolute;
    top: 0;
    width: 115px;
    height: 53px;
    background-image: url("/images/rune-logo.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.ae_package h3 {
    padding-bottom: 40px;
    margin-bottom: 40px;
    position: relative;
}

.ae_package h3::before {
    content: "";
    width: 55px;
    height: 1px;
    background: var(--gray3);
    position: absolute;
    bottom: 0;
    left: 0;
}

.ae_package p {
    margin-bottom: 40px;
}

.ae_package a {
    margin-top: auto;
}

@media (max-width: 1250px) {
    .ae_package a {
        min-width: 100%;
    }
}

@media (max-width: 1199px) {
    .ae_package.rune-badge::before {
        width: 70px;
    }
    .ae_package {
        margin: 8px;
        height: auto;
    }
}

@media (max-width: 768px) {
}

/** Services **/

.ae_services {
    width: 280px;
    margin: 0 15px 30px;
    text-align: center;
}

/*.ae_services .ae_icon {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 280px;
    border-radius: 16px;
    border: 3px solid var(--gray6);
}*/

.ae_services > img {
}

.ae_services h5 {
    margin: 30px 0 10px;
}

.ae_services p {
    width: 85%;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .ae_services > img {
        width: 100px;
    }
}

/** Package Options **/

.ae_package-options {
    padding: 72px;
    border-radius: 16px;
}

.ae_package-option {
    text-align: center;
    border-right: 1px solid var(--gray5);
}

.ae_package-option:last-of-type {
    border-right: none;
}

.ae_package-option h3,
.ae_package-option h4 {
    margin: 60px 0 40px;
    padding-bottom: 40px;
    position: relative;
}

.ae_package-option h3 > span,
.ae_package-option h4 > span {
    font-size: 28px;
    font-weight: 800;
    display: block;
}

.ae_package-option h3::before,
.ae_package-option h4::before {
    content: "";
    width: 55px;
    height: 1px;
    background: var(--gray4);
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
}

.ae_package-option p {
    font-size: 16px;
    line-height: 24px;
}

.ae_package-option .price {
    line-height: 20px;
    padding-top: 15px;
}

.ae_package-option .price > strong {
    font-size: 23px;
    font-weight: 800;
}

.ae_package-option a {
    margin-top: 20px;
    font-weight: 700;
    text-decoration: none;
    display: inline-block;
}

.ae_package-option img {
    margin: 20px auto 0;
    display: block;
}

body.poslovni .ae_package-option .icon-package-opt path {
    fill: var(--primaryBlue);
}

body.poslovni .ae_section-options-2 .icon-package-opt path {
    fill: var(--blueDark);
}

@media (max-width: 1199px) {
    .ae_package-options {
        background: none !important;
    }
    .ae_package-options.pale-pink-bg .ae_package-option {
        background-color: var(--pinkPale);
    }
    .ae_package-options.pale-blue-bg .ae_package-option {
        background-color: var(--bluePale);
    }
    .ae_package-option {
        border: none;
        border-radius: 8px;
        padding: 32px;
        margin: 10px;
        width: calc(50% - 20px);
    }
}

@media (max-width: 991px) {
    .ae_package-options {
        padding: 0px;
    }
}

@media (max-width: 768px) {
    .ae_package-option {
        width: 100%;
    }
    body[class*="optika_"] .ae_package-options {
        overflow: hidden;
        overflow-x: visible;
    }
    body[class*="optika_"] .ae_package-option {
        width: calc(25% - 20px);
    }
    body[class*="optika_"] .ae_package-options > .row {
        width: 1000px;
    }
    .ae_package-options.ae_scrollbar {
        scrollbar-color: var(--primaryPink);
    }
    .ae_package-options.ae_scrollbar::-webkit-scrollbar-thumb {
        background-color: var(--primaryPink);
    }
    body.poslovni .ae_package-options.ae_scrollbar {
        scrollbar-color: var(--primaryBlue);
    }
    body.poslovni .ae_package-options.ae_scrollbar::-webkit-scrollbar-thumb {
        background-color: var(--primaryBlue);
    }
}

.ae_package-details {
    padding: 54px 64px;
    border-radius: 16px;
    margin: 36px 0;
}

.ae_package-details dt,
.ae_package-details dd {
    font-weight: 800;
    font-size: 23px;
    /*line-height: 32px;*/
    line-height: 24px;
    margin: 10px 0;
}
.ae_package-details dt strong {
    font-weight: 800;
}

.ae_package-details dt {
    padding-right: 20px;
    width: 70%;
}

.ae_package-details dd {
    width: 30%;
    text-align: right;
}

.ae_package-details dt span,
.ae_package-details dd span {
    font-size: 20px;
    font-weight: 300;
}

@media (max-width: 768px) {
    .ae_package-details {
        padding: 16px 32px;
    }
    .ae_package-details dt,
    .ae_package-details dd {
        width: 100%;
        text-align: left;
    }
    .ae_package-details dt.d-flex,
    .ae_package-details dt.d-flex img {
        display: block !important;
    }
}

/** SITE HEADER + NAV **/

.ae_site-header nav {
    display: flex;
}

.ae_site-header nav a:first-of-type::before {
    content: "";
    width: 1px;
    height: 24px;
    background: var(--gray5);
    position: absolute;
    right: 0;
}

body.home .ae_site-header nav a {
    font-weight: bold;
    font-size: 16.5px;
    line-height: 24px;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    padding: 44px 16px;
    position: relative;
    color: var(--gray2);
}

body.home .ae_site-header nav a:first-of-type {
    padding-left: 50px;
}

body.privatni .ae_site-header nav a.active,
body.privatni .ae_site-header nav a.link-privatni {
    color: var(--pinkLight);
}

body.poslovni .ae_site-header nav a.active,
body.poslovni .ae_site-header nav a.link-poslovni {
    color: var(--blueLight);
}

body.home .ae_site-header nav a.btn-app {
    padding-left: 50px;
    padding-right: 50px;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    margin-left: auto;
    color: var(--gray2);
}

.ae_site-header nav a.btn-app > svg {
    margin-right: 18px;
    transform: translateY(-2px);
}

body.poslovni .ae_site-header nav a.btn-app > svg path,
body.poslovni .ae_site-header nav a.btn-app > svg circle {
    stroke: var(--primaryBlue);
}

body .ae_site-header nav a.btn-app > svg path,
body .ae_site-header nav a.btn-app > svg circle {
    stroke: var(--primaryPink);
}

.ae_social {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 26px;
}

.ae_social a:hover svg stop {
    stop-color: var(--pinkDark);
}

body.poslovni .ae_social a:hover svg stop {
    stop-color: var(--blueDark);
}

@media (max-width: 768px) {
    body.home .ae_site-header nav a {
        padding: 16px 24px 16px 12px;
        font-size: 12.5px;
    }
    body.home .ae_site-header nav a:first-of-type {
        padding-left: 24px;
        padding-right: 12px;
    }
    body.home .ae_site-header nav a.btn-app {
        padding: 16px 24px;
    }
}

/** SITE FOOTER **/

footer {
    position: relative;
    /*z-index: 1;*/
}

footer p {
    font-size: 16.5px;
    line-height: 32px;
}

.footer-1 {
    background: #fff;
    padding: 112px 0;
    margin-top: 60px;
}

.footer-1 .col {
    text-align: center;
}

body.home .footer-1 a {
    font-weight: 700;
    color: var(--gray3);
}

body.home .footer-1 a:hover {
    color: var(--pinkDark);
}

body.poslovni .footer-1 a:hover {
    color: var(--blueDark);
}

.footer-1 p {
    color: var(--gray3);
}

.footer-2 {
    background: var(--gray6);
    padding: 30px;
}

.footer-2 p,
.footer-2 a {
    color: var(--gray4);
    margin: 0;
    font-size: 14px;
    line-height: 24px;
}

body.privatni .footer-2 a,
body.poslovni .footer-2 a {
    color: var(--gray3);
}

.footer-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.footer-menu a::before {
    content: "";
    background-color: var(--gray3);
    height: 100%;
    width: 0.5px;
    position: absolute;
    right: -10px;
}

.footer-menu a:last-of-type:before {
    content: none;
}

@media (max-width: 768px) {
    .footer-1 {
        padding: 64px 0;
    }
    .footer-2 .col,
    .footer-1 .col {
        text-align: center;
        padding: 10px 0;
    }
    .footer-menu {
        justify-content: space-around;
    }
    .footer-menu a::before {
        content: none;
    }
    .footer-2 > .row > .col:first-of-type {
        order: 2;
        padding-top: 22px;
    }
    .footer-menu a {
        width: 100%;
    }
}

/** Page Headers **/

.ae_page-header {
    padding-top: 12px;
}

.ae_page-header h1 {
    font-weight: 200;
}

.ae_page-header h4 {
    font-weight: 800;
}

.ae_page-header img {
    display: block;
    margin: 0 auto;
}

.ae_page-header .logo {
    margin-bottom: 84px;
}

.ae_page-header .arrow-down {
    margin-top: 75px;
    margin-bottom: 75px;
}

.ae_page-header .container {
    max-width: 914px;
}

.ae_page-header p {
    margin-top: 38px;
    margin-bottom: 122px;
    text-align: left;
}

@media (max-width: 768px) {
    .ae_page-header .logo {
        margin-top: 64px;
    }
}

body:not(.common_faq) .ae_common-page .container {
    max-width: 660px;
}

body.common_faq .ae_common-page .container {
    background-color: #fff;
    border-radius: 8px;
    padding: 5% 10%;
}

.ae_common-page a {
    color: var(--pinkDark);
    font-weight: 700;
}

.ae_common-page h2,
.ae_common-page h3,
.ae_common-page h4,
.ae_common-page h5,
.ae_common-page h6 {
    margin-top: 20px;
}

.ae_common-page ol,
.ae_common-page ul {
    margin: 20px 0 30px 0;
}

.ae_common-page ol li,
.ae_common-page ul li {
    margin-bottom: 15px;
}

.ae_accordion-btn {
    background: none;
    border: none;
    color: var(--pinkDark);
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    padding: 30px 30px 30px 0;
    border-bottom: 1px solid #e0e0e0;
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
}

.ae_accordion-btn::after {
    content: "+";
    position: absolute;
    right: 0;
    color: #828282;
}

.ae_accordion-btn.active::after {
    content: "-";
}

.ae_accordion_panel {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in;
    padding: 30px;
    opacity: 0;
    margin-top: -60px;
    visibility: hidden;
}

.ae_accordion-btn.active + .ae_accordion_panel {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
}

@media (max-width: 768px) {
    .ae_accordion-btn {
        font-size: 24px;
        line-height: 32px;
    }
}




.lds-ring {
    width: 100%;
    height: 100vh;
    z-index: 999;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #00000040;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}